@font-face {
  font-family: 'Aileron';
  src: url('/assets/fonts/aileron/Aileron-Black.otf') format('opentype');
  font-weight: black;
  font-style: normal;
}

@font-face {
  font-family: 'Aileron';
  src: url('/assets/fonts/aileron/Aileron-BlackItalic.otf') format('opentype');
  font-weight: black;
  font-style: italic;
}

@font-face {
  font-family: 'Aileron';
  src: url('/assets/fonts/aileron/Aileron-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Aileron';
  src: url('/assets/fonts/aileron/Aileron-BoldItalic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Aileron';
  src: url('/assets/fonts/aileron/Aileron-Italic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Aileron';
  src: url('/assets/fonts/aileron/Aileron-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

