.headline {
  background-color: lighten($brand-color, 0%);
  color: white;
  padding: 0.3em 1em 0.3em 1em;

  .make-room-for-menu-icon {
    display: inline-block;
    margin-right: $menu-icon-width;
  }
}
