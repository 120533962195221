// CONTRACT: directionAsText is either exactly "left" or exactly "right"
@mixin pulled-quote($directionAsText) {
  @extend %callout;
  display: block;
  background-color: lighten($brand-color, 30%);
  // "left" is not the same as left.
  float: if($directionAsText == "left", left, right);
  width: 40%;
  min-width: 200px;
  font-size: 120%;
  padding: 0.5em 0.2em 0.5em 0.5em !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  $direction-to-the-edge-of-the-content-area: if($directionAsText == "left", "left", "right");
  $direction-to-the-surrounding-text: if($directionAsText == "left", "right", "left");
  margin-#{$direction-to-the-edge-of-the-content-area}: 0 !important;
  margin-#{$direction-to-the-surrounding-text}: 1.5em !important;

  &:before {
    @extend %open-quote;
  }
}

.post-header {
  margin-top: 1em;
  margin-bottom: $spacing-unit;
}

.post-title {
  margin-top: 0 !important;
  font-size: 180%;
  letter-spacing: -1px;
  line-height: 1;
}

.post-content {
  margin-bottom: $spacing-unit;

  h1, h2, h3, h4, h5, h6 {
    font-family: $headline-font-family !important;
    font-weight: bold;
  }

  h1, h2, h3, h4 {
    margin-top: 1.5em;
  }

  h1 {
    font-size: 180%;
  }

  h2 {
    font-size: 150%;
  }

  h3 {
    font-size: 125%;
  }

  h4 {
    font-size: 110%;
  }

  .footnotes {
    margin-top: 2em;
    padding-top: 1em;
    border-top: 1px dotted;
  }

  section, aside {
    @extend %vertical-rhythm;

    :first-child { margin-top: 0; }
  }

  %callout {
    color: lighten($text-color, 30%);
    border-left: 4px solid $brand-color;
    padding-left: $spacing-unit / 2;
    margin-left: 2em;

    > :last-child {
      margin-bottom: inherit !important;
    }
  }

  figure {
    // bound the box and let the image be smaller
    width: 80%;

    // center the caption
    text-align: center;

    margin-left: auto;
    margin-right: auto;
    margin-top: inherit;
    margin-bottom: inherit;

    img, figcaption {
      margin-left: auto;
      margin-right: auto;
    }
  }

  figure.interstitial {
    width: 20em;
    max-width: 80%;

    img {
      width: 6em;
    }
  }

  figure.icon {
    width: 128px;
    max-width: 50%;
    height: auto;

    img {
      width: 100%;
    }
  }

  img.paragraph-eyecatcher {
    float: right;
    margin-left: 1ex;
    margin-bottom: 1ex;

    width: 20%;
    max-width: 150px;
  }

  .centered-text-flow-around {
    // We need display: block in order to ensure that we can center this item
    display: block;
    text-align: center;
    margin: auto;

    margin-top: 1em;
    margin-bottom: 1em;
  }

  // https://www.h3xed.com/web-development/how-to-make-a-responsive-100-width-youtube-iframe-embed
  .embedded-video-container {
    position: relative;
    height: 0;
    padding-bottom: 65%; // tweaked visually

    .embedded-video {
      position: absolute;
      top: 0; left: 0;
      width: 100%; height: 100%;
    }
  }

  .body-text-block {
    max-width: 90%;
    width: 600px;

    @extend .centered-text-flow-around;
  }

  blockquote {
    @extend %callout;
  }

  aside, .aside, .definition, .instruction, .introductory-note, .note, .highlight {
    @extend %callout;
  }

  .promotion {
    @extend %content-tile-background;
    @include drop-shadow(12px, $grey-color-dark);
  }

  .diagram {
    @extend figure;
    display: block;
    border: 1px solid;
  }

  .promotional-image, .lead-image {
    float: right;
    padding-left: 2em;
    width: 40%;
  }

  .rounded-button {
    padding: 0.5em;
    background-color: $brand-color;
    color: white;
    border-radius: 0.7em 0.2em 0.7em 0.2em;
  }

  .youtube {
    @extend figure;
    width: 400px;
    height: 320px;
  }

  .bdd-scenario {
    @extend %callout;
  }

  .emphasized-paragraph, .guideline {
    background-color: lighten($brand-color, 20%);
    font-size: 110%;
    margin: 1em;
    padding: 1em !important;
  }

  .footnotes {
    /* HACK Until I can figure out how not to generate the <hr /> at all. */
    hr { display: none; }
  }

  .pulled-quote-left { @include pulled-quote("left"); }
  .pulled-quote-right { @include pulled-quote("right"); }

  pre {
    max-width: inherit;
    display: block;
    margin-left: -10%;
    margin-right: -10%;

    @include drop-shadow(6px, $grey-color-dark);
    border: 1px solid $grey-color-dark;

    @include media-query($content-width * 1.2) {
      & {
        margin-left: inherit;
        margin-right: inherit;
      }
    }
  }

  .sourceCode {
    font-family: $code-font-family;
    font-size: smaller;

    color: black;

    // Stupid workaround for how Jekyll renders code blocks.
    a {
      color: inherit;
    }

    // How Jekyll marks comments
    .co {
      color: $brand-color;
    }
  }

  // <div class="list-of-paragraphs"><ol>blah blah blah</ol></div>
  // Use when the list items are paragraphs instead of short bullet points
  .list-of-paragraphs {
    li {
      @extend %vertical-rhythm;
    }
  }

  details {
    border: 1px solid;
    padding: 0.5em;

    &[open] > summary {
      @extend %vertical-rhythm;
      font-weight: bold;
    }
  }
}

