@charset "utf-8";

@import url(//fonts.googleapis.com/css?family=Roboto:400,700,400italic,700italic);
@import url(//fonts.googleapis.com/css?family=Quando);
@import url(//fonts.googleapis.com/css?family=Source+Sans+Pro);
@import url(//fonts.googleapis.com/css?family=Fira+Code);
@import "aileron-font.scss";
@import "bebas-neue-font.scss";


// Our variables
$base-font-family: "Aileron", "Source Sans Pro", "Roboto", "PT Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size:   1.4rem;
$base-font-weight: normal;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.6;

$headline-font-family: "Bebas Neue", "Quando", "Open Sans", serif;
$headline-line-height: 1.2;

$code-font-family: "Fira Code", monospace;

$spacing-unit:     30px;

$text-color:       #000000;
$background-color: #e3e3e3; 
$brand-color:      #e36877;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

$content-width:    920px;

$on-mobile:        600px;
$on-tablet:        $content-width;
$on-laptop:        2400px;

// Use media queries like this:
// @include media-query($on-mobile) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
  "base",
  "layout",
  "syntax-highlighting",
  "headline";

.single-post-tile {
  @extend %content-tile-background;
  padding: 2 * $default-content-tile-padding;
  @include media-query($on-mobile) {
    padding: 0.5 * $default-content-tile-padding;
  }
}

.post {
  @include media-query($on-mobile) {
    font-size: 70%;
  }
  @import "post";
}
