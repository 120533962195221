$menu-icon-width: 36px;

.site-header {
  // Positioning context for the mobile navigation icon
  position: relative;
}

.hero {
  border-top: 6px solid $brand-color;
  padding-top: 0.5rem;
}

.site-title {
  font-size: 100%;
  margin-bottom: 1em;
  float: left;

  .logo {
    width: 180px;
  }

  &,
  &:visited {
    color: $grey-color-dark;
  }
}

.site-nav {
  float: right;

  .menu-icon {
    display: none;
  }

  .page-link {
    display: inline;
    color: $text-color;

    &:not(:last-child) {
      margin-right: 1em;
    }
  }

  @include media-query($on-mobile) {
    position: fixed;
    top: 10px;
    right: 10px;
    background-color: $background-color;
    border: 1px solid $grey-color-light;
    border-radius: 5px;
    text-align: right;

    .menu-icon {
      display: block;
      float: right;
      width: $menu-icon-width;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg {
        width: 18px;
        height: 15px;

        path {
          fill: $grey-color-dark;
        }
      }
    }

    .trigger {
      clear: both;
      display: none;
    }

    &:hover .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .page-link {
      display: block;
      padding: 5px 10px;

      &:not(:last-child) {
        margin-right: 0;
      }
      margin-left: 20px;
    }
  }
}

/**
* Content footer, which refers to the foot of the content section, not the page.
* REFACTOR Rename "footer" to "offers".
*/
.content-footer {
  border-top: 1px solid $grey-color-light;
}

.footer-heading {
}

.contact-list,
.social-media-list,
.online-training-course-list {
  list-style: none;
  margin-left: 0;
}

.online-training-course-list li {
  margin-bottom: 1em;

  :last-child {
    margin-bottom: 0;
  }
}

@include media-query($on-mobile) {
  // layout footer as a single column
  .content-footer {
    display: flex;
    flex-direction: column;
    column-gap: 1em;
  }
}

@media screen and (min-width: $on-tablet) {
  .content-footer {
    display: grid;
    column-gap: 2em;
    grid-template-areas:
      "courses courses"
      "contact-methods tagline";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }

  .contact-methods {
    grid-area: contact-methods;
  }

  .tagline {
    grid-area: tagline;
  }

  .courses {
    grid-area: courses;

    .online-training-course-list {
      display: flex;
      flex-direction: row;
      gap: 2em;
    }
  }
}

/**
* Page content
*/
.page-content {
  padding: $spacing-unit 0;
}

.rss-subscribe {
  float: right;
}

.page-heading {
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-headline {
}

.post-summary {
  @extend %content-tile-background;
}

.archives-tile,
.series-tile {
  @extend %content-tile-background;
}

.series-tile {
  @extend %vertical-rhythm;
}

.archives {
  .post-link {
    line-height: 1;
    display: inline;
    font-size: 100%;
  }

  > li {
    margin-bottom: 0;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-category-item {
  &:not(:last-child):after {
    content: ", ";
  }
}

.post-link {
  display: block;
}

/*
 * Site footer
 */
footer {
  font-size: 80%;

  text-align: center;
}
