/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6, p, blockquote, pre, hr, dl, dd, ol, ul, figure, details, summary {
    margin: 0;
    padding: 0;
}

/*
 * No extra vertical space
 */
sub, sup {
  line-height: 1;
}

%basic-styling {
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  color: $text-color;
  background-color: $background-color;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
}

$default-content-tile-padding: 1rem;

%content-tile-background {
  border: 1px solid $grey-color-dark;
  border-top: 6px solid $brand-color;
  padding: $default-content-tile-padding;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 0.4rem;
}

body {
  @extend %basic-styling;
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6, p, blockquote, pre, ul, ol, dl, figure, details,
%vertical-rhythm {
    margin-bottom: $spacing-unit / 2;
}

img {
    max-width: 100%;
    vertical-align: middle;
}

figure > img {
    display: block;
}

figcaption {
    font-size: $small-font-size;
}

ul, ol {
    margin-left: $spacing-unit;
}

li {
    > ul,
    > ol {
         margin-bottom: 0;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-weight: $base-font-weight;
}

a {
    color: $brand-color;
    text-decoration: none;

    &:visited {
        color: $brand-color;
    }

    &:hover {
        color: $brand-color;
        text-decoration: underline;
    }
}

%open-quote {
  content: "\201C";
  font-family: "Times New Roman", serif;
  color: lighten($text-color, 80%);
  font-size: 4em;
  line-height: 0;
  vertical-align: -0.4em;
}

blockquote {
    padding-left: $spacing-unit / 2;
    margin-left: 3em;

    > :nth-child(1):before {
      @extend %open-quote;
    }

    > :last-child {
        margin-bottom: inherit;
    }
}

pre,
code {
    border: 1px solid $grey-color-light;
    border-radius: 3px;
    background-color: #eef;
}

code {
    padding: 1px 5px;
}

pre {
    padding: 8px 12px;
    overflow-x: auto;

    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}

@mixin drop-shadow($width, $color) {
    box-shadow: $width $width 5px $color;
}

.wrapper {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
    max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
    @extend %clearfix;

    @include media-query($on-laptop) {
        max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
        max-width:         calc(#{$content-width} - (#{$spacing-unit}));
        padding-right: $spacing-unit / 2;
        padding-left: $spacing-unit / 2;
    }
}

%clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

.icon {
    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;

        path {
            fill: $grey-color;
        }
    }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $headline-font-family;
  line-height: $headline-line-height;
}
